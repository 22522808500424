import {
    helpInfo
} from "./helpInfo";
import {
    paramParse
} from "./parameter";
import * as util from "./util"

const error = `<span style="background-color:#c71714;padding:1px">Error</span><span> 未找到命令</span>`;

const typeMap = new Map([
    ['baidu', 'https://www.baidu.com/s?ie=utf-8&wd='],
    ['bing', 'https://www.bing.com/search?q='],
    ['google', 'https://www.google.com/search?q='],
    ['baidudev', 'https://kaifa.baidu.com/searchPage?module=SEARCH&wd='],
    ['bilibili', 'https://search.bilibili.com/all?keyword='],
    ['github', 'https://github.com/search?q='],
    ['gitee', 'https://search.gitee.com/?skin=rec&type=repository&q='],
    ['blackvon', 'https://www.blackvon.cn/#/?key=']
]);

const cmdHelp = (cmd) => {
    let res = '';
    if (cmd == 'show') {
        helpInfo.forEach(element => {
            res += element.sub;
            return;
        });
    } else {
        let arr = paramParse(cmd);
        if (arr.length === 2) {
            helpInfo.forEach(element => {
                if (element.name === arr[1]) {
                    res = element.info;
                }
                return;
            });
        }
    }
    return res;
}

const cmdGoto = (cmd) => {
    let arr = paramParse(cmd);
    if (arr.length === 2) {
        util.openNewWindow(arr[1]);
        return "";
    }
    if (arr.length === 3 && (arr[2] === '-s' || arr[2] === '--self')) {
        util.openThisWindow(arr[1]);
        return "";
    }
    return error;
}

const cmdSearch = (cmd) => {
    let type = 'baidu';
    let arr = paramParse(cmd);
    if (arr.length === 3 && (arr[2] === '-s' || arr[2] === '--self')) {
        util.search(arr[1], type, true, typeMap);
        return "";
    }
    if (arr.length === 4 && (arr[2] === '-f' || arr[2] === '--from')) {
        util.search(arr[1], arr[3], false, typeMap);
        return "";
    }
    if (arr.length === 5) {
        if ((arr[2] === '-s' || arr[2] === '--self') && (arr[3] === '-f' || arr[3] === '--from')) {
            util.search(arr[1], arr[4], true, typeMap);
        }
        if ((arr[4] === '-s' || arr[4] === '--self') && (arr[2] === '-f' || arr[2] === '--from')) {
            util.search(arr[1], arr[3], true, typeMap);
        }
        return "";
    }
    if (arr.length >= 2) {
        let content = "";
        for (let i = 1; i < arr.length; i++) {
            content += arr[i] + " ";
        }
        util.search(content.trim(), type, false, typeMap);
        return "";
    }
    return error;
}

const cmdSearchType = (cmd, type) => {
    let arr = paramParse(cmd);
    if (arr.length === 3 && (arr[2] === '-s' || arr[2] === '--self')) {
        util.search(arr[1], type, true, typeMap);
        return "";
    }
    if (arr.length >= 2) {
        let content = "";
        for (let i = 1; i < arr.length; i++) {
            content += arr[i] + " ";
        }
        util.search(content.trim(), type, false, typeMap);
        return "";
    }
    return error;
}

//命令列表
const commands = [{
    name: ["show"],
    get(cmd) {
        return cmdHelp(cmd);
    }
}, {
    name: ["info"],
    get() {
        return "<p>© 2019-2022 blackvon.cn 版权所有.</p>";
    }
}, {
    name: ["goto", "to", "open", "visit", "jump"],
    get(cmd) {
        return cmdGoto(cmd);
    }
}, {
    name: ["search", "s", "sousuo", "sou", "query"],
    get(cmd) {
        return cmdSearch(cmd);
    }
}, {
    name: ["baidu"],
    get(cmd) {
        return cmdSearchType(cmd, "baidu");
    }
}, {
    name: ["bing"],
    get(cmd) {
        return cmdSearchType(cmd, "bing");
    }
}, {
    name: ["google"],
    get(cmd) {
        return cmdSearchType(cmd, "google");
    }
}, {
    name: ["baidudev", "dev", "kaifa"],
    get(cmd) {
        return cmdSearchType(cmd, "baidudev");
    }
}, {
    name: ["bilibili", "bzhan", "bili"],
    get(cmd) {
        return cmdSearchType(cmd, "bilibili");
    }
}, {
    name: ["github"],
    get(cmd) {
        return cmdSearchType(cmd, "github");
    }
}, {
    name: ["gitee"],
    get(cmd) {
        return cmdSearchType(cmd, "gitee");
    }
}, {
    name: ["blackvon", "b", "bl", "black"],
    get(cmd) {
        return cmdSearchType(cmd, "blackvon");
    }
}]

const commandParse = (cmd) => {
    let res = error;
    commands.forEach(ele => {
        let arr = paramParse(cmd);
        ele.name.forEach(it => {
            if (arr[0] === it) {
                res = ele.get(cmd);
                return;
            }
        });
    });
    return res;
}

export {
    commandParse
}
