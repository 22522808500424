<template>
  <div>
    <v-shell :banner="banner" :shell_input="send_to_terminal" :commands="commands" @shell_output="prompt"
      class="content"></v-shell>
  </div>
</template>

<script>
import { commandParse } from '../utils/commands.js'
export default {
  name: 'IndexPage',
  data() {
    return {
      send_to_terminal: "",
      banner: {
        header: "BIndex",
        subHeader: "© 2022-2023 blackvon.cn 版权所有",
        helpHeader: 'Enter "show" for more information.',
        emoji: {
          time: 750
        },
        sign: " [local] $"
      },
      commands: []
    };
  },
  methods: {
    prompt(value) {
      console.log(value);
      console.log(commandParse(value));
      this.send_to_terminal = commandParse(value);
    }
  },
  mounted() {
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        const height= document.documentElement.clientHeight;
        window.scrollTo(0,height)
      }
    }
  }
};
</script>

<style>
.content {
  padding: 0;
  margin: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.content #container {
  width: 85%;
}
</style>
