const helpInfo = [{
    name: "show",
    sub: "<h4>show <命令名>，包含如下命令：</h4>",
    info: `
        show 显示命令

        用法：show <命令名>
        参数：
            cmd 非必填 待查看的命令名
        `
}, {
    name: "info",
    sub: "<p>info 本网站信息介绍</p>",
    info: `
        info 本网站信息介绍
        `
}, {
    name: "goto",
    sub: "<p>goto 网页跳转</p>",
    info: `
        goto 网页跳转

        别名：to, open, visit, jump
        用法：goto <目标链接> [-s 是否当前页面打开]
        参数：
            link 必填 目标链接
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "search",
    sub: "<p>search 网页搜索</p>",
    info: `
        search 网页搜索
        介绍：支持从不同平台快捷搜索内容

        别名：s, sousuo, sou, query
        用法：search <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
            -f, --from 可选 可选项：baidu;bing;google;baidudev;bilibili;github;gitee;blackvon 默认：baidu
        `
}, {
    name: "baidu",
    sub: "<p>baidu 百度搜索</p>",
    info: `
        baidu 百度搜索

        用法：baidu <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "bing",
    sub: "<p>bing 必应搜索</p>",
    info: `
        bing 必应搜索

        用法：bing <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "google",
    sub: "<p>google Google 搜索</p>",
    info: `
        google Google 搜索

        用法：google <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "baidudev",
    sub: "<p>baidudev 百度开发者搜索</p>",
    info: `
        baidudev 百度开发者搜索

        别名：dev, kaifa
        用法：baidudev <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "bilibili",
    sub: "<p>bilibili 哔哩哔哩搜索</p>",
    info: `
        bilibili 哔哩哔哩搜索

        别名：bzhan, bili
        用法：bilibili <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "github",
    sub: "<p>github GitHub搜索</p>",
    info: `
        github GitHub搜索

        用法：github <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "gitee",
    sub: "<p>gitee Gitee搜索</p>",
    info: `
        gitee Gitee搜索

        用法：gitee <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "blackvon",
    sub: "<p>blackvon blackvon博客搜索</p>",
    info: `
        blackvon blackvon博客搜索

        别名：b, bl, black
        用法：blackvon <搜索内容>
        参数：
            word 必填 搜索内容
        选项：
            -s, --self 可选 是否当前页面打开
        `
}, {
    name: "clear",
    sub: "<p>clear 清空面板</p>",
    info: `
        clear 清空面板
        `
}];

export {
    helpInfo
}
