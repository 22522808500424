const openNewWindow = (url) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.target = "_blank";
    a.href = url.indexOf('https://') != -1 || url.indexOf('http://') != -1 ? url : "http://" + url;
    a.click();
    document.body.removeChild(a);
}

const openThisWindow = (url) => {
    location.href = url.indexOf('https://') != -1 || url.indexOf('http://') != -1 ? url : "http://" + url;
}

const search = (content, type, isSelf, typeMap) => {
    let url = typeMap.get('baidu');
    if (typeMap.has(type)) {
        url = typeMap.get(type);
    }
    console.log(url + enSearchKey(content))
    if (isSelf) {
        openThisWindow(url + enSearchKey(content));
    } else {
        openNewWindow(url + enSearchKey(content));
    }
}
const enSearchKey = (key) => {
    key = key.replaceAll('%', "%25");
    key = key.replaceAll('&', "%26");
    key = key.replaceAll('+', "%2B");
    key = key.replaceAll('/', "%2F");
    key = key.replaceAll('?', "%3F");
    key = key.replaceAll('#', "%23");
    key = key.replaceAll('=', "%3D");
    key = key.replaceAll(' ', "%20");
    return key;
}

export {
    openNewWindow,
    openThisWindow,
    search,
    enSearchKey
}